import { builder } from '@builder.io/react';

import { initializeBuilder } from '~src/builder';
import { BodilBuilderPage } from '~src/components/BodilBuilderPage';

initializeBuilder();

export const getStaticProps = async () => {
  const [content, footer, header] = await Promise.all([
    builder
      .get('page', {
        cachebust: true,
        enrich: true,
        includeRefs: true,
        noTraverse: false,
        userAttributes: { urlPath: '/404' },
      })
      .toPromise(),
    builder.get('footer', { cachebust: true, enrich: true, includeRefs: true, noTraverse: false }).toPromise(),
    builder.get('header', { cachebust: true, enrich: true, includeRefs: true, noTraverse: false }).toPromise(),
  ]);

  return {
    props: {
      content,
      footer,
      header,
    },
  };
};

export default BodilBuilderPage;
